.page {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .mainSubStructure {
    flex: 1;
  }
}

.form {
  margin-top: 20px;
}

.set {
  display: flex;
  height: 58vh;

  > div {
    flex: 1.2;
    border: 1px solid #eee;
    overflow-y: auto;
  }

  > div:last-child {
    flex: 5;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    padding: 16px;
  }
}

.checkList {
  padding: 16px 0 16px 16px;

  &:hover {
    background-color: rgb(234, 240, 247);
  }
}
